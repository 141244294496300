import React from 'react';
import { Editor } from '@tiptap/react';
import TiptapButton from '@symmetre-web/text-editor/components/ui/TextEditorButton';
import { ReactComponent as BlockquoteIcon } from '@symmetre-web/text-editor/icons/blockQuote.svg';

interface BlockquoteButtonProps {
  editor: Editor;
}

const BlockquoteButton: React.FC<BlockquoteButtonProps> = ({ editor }) => {
  const isActive = editor.isActive('blockquote');
  const canToggle = editor.can().chain().focus().toggleBlockquote().run();

  return (
    <TiptapButton
      active={isActive}
      disabled={!canToggle}
      onClick={() => editor.chain().focus().toggleBlockquote().run()}
      tooltipProps={{
        mainText: 'Blockquote',
      }}
    >
      <BlockquoteIcon />
    </TiptapButton>
  );
};

export default BlockquoteButton;

import * as React from 'react';
import type { Editor } from '@tiptap/react';
import type { Level } from '@tiptap/extension-heading';
import type { FormatAction } from '@symmetre-web/text-editor/types';

// TODO: FE-3618 Move stories to UI package
import { Button } from '@symmetre-web/text-editor/components/ui/Button';
import {
  Dropdown,
  DropdownItem,
} from '@symmetre-web/text-editor/components/ui/Dropdown';
import { Icon } from '@symmetre-web/text-editor/components/ui/Icon';
import { Tooltip } from '@symmetre-web/text-editor/components/ui/Tooltip';

interface HeadingStyle
  extends Omit<
    FormatAction,
    'value' | 'icon' | 'action' | 'isActive' | 'canExecute'
  > {
  element: keyof React.JSX.IntrinsicElements;
  className: string;
  level?: Level;
}

const FORMAT_ACTIONS: HeadingStyle[] = [
  {
    label: 'Normal Text',
    element: 'span',
    className: 'grow',
    shortcuts: ['mod', 'alt', '0'],
  },
  {
    label: 'Heading 1',
    element: 'h1',
    level: 1,
    className: 'm-0 grow text-3xl font-extrabold',
    shortcuts: ['mod', 'alt', '1'],
  },
  {
    label: 'Heading 2',
    element: 'h2',
    level: 2,
    className: 'm-0 grow text-xl font-bold',
    shortcuts: ['mod', 'alt', '2'],
  },
];

interface HeadingStyleProps {
  editor: Editor;
}

export const HeadingStyle: React.FC<HeadingStyleProps> = ({
  editor,
}: HeadingStyleProps) => {
  const handleStyleChange = React.useCallback(
    (level?: Level) => {
      if (level) {
        editor.chain().focus().toggleHeading({ level }).run();
      } else {
        editor.chain().focus().setParagraph().run();
      }
    },
    [editor],
  );

  const getCurrentStyle = React.useCallback(() => {
    for (const action of FORMAT_ACTIONS) {
      if (action.level && editor.isActive('heading', { level: action.level })) {
        return action.label;
      }
    }
    return FORMAT_ACTIONS[0].label;
  }, [editor]);

  return (
    <Dropdown
      items={
        <>
          {FORMAT_ACTIONS.map((action, index) => (
            <DropdownItem
              key={`heading-${index}`}
              onClick={() => handleStyleChange(action.level)}
            >
              {action.label}
            </DropdownItem>
          ))}
        </>
      }
      placement="bottom-start"
    >
      <Tooltip mainText="Heading">
        <div className="w-[120px]">
          <Button
            variant="secondary"
            size="xs"
            iconPosition="right"
            iconName="bottom"
            fluid
            className="flex justify-between"
          >
            <div className="flex justify-between">
              <div className="text-dark-20">{getCurrentStyle()} </div>
              <Icon iconName="caret-down" />
            </div>
          </Button>
        </div>
      </Tooltip>
    </Dropdown>
  );
};

export default HeadingStyle;

import { mergeAttributes, getMarkRange } from '@tiptap/react';
import TiptapLink from '@tiptap/extension-link';
import type { EditorView } from '@tiptap/pm/view';
import { Plugin, TextSelection } from '@tiptap/pm/state';
import { getLinkParseRule } from '@symmetre-web/text-editor/utils/utils';

export const Link = TiptapLink.extend({
  inclusive: false,
  parseHTML() {
    return getLinkParseRule();
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = {
      ...HTMLAttributes,
      target: '_blank',
      rel: 'noopener noreferrer',
    };
    return ['a', mergeAttributes(this.options.HTMLAttributes, attrs), 0];
  },

  addOptions() {
    return {
      ...this.parent?.(),
      openOnClick: false,
      HTMLAttributes: {
        class: 'link',
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      protocols: ['http', 'https', 'mailto', 'tel'],
      autolink: false,
    };
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      ...(this.parent?.() || []),
      new Plugin({
        props: {
          handleKeyDown: (_: EditorView, event: KeyboardEvent) => {
            const { selection } = editor.state;

            if (event.key === 'Escape' && selection.empty !== true) {
              editor.commands.focus(selection.to, { scrollIntoView: false });
            }

            return false;
          },
          handleClick(view, pos) {
            const { schema, doc, tr } = view.state;
            const range = getMarkRange(doc.resolve(pos), schema.marks.link);

            if (!range) {
              return;
            }

            const { from, to } = range;
            const start = Math.min(from, to);
            const end = Math.max(from, to);

            if (pos < start || pos > end) {
              return;
            }

            const $start = doc.resolve(start);
            const $end = doc.resolve(end);
            const transaction = tr.setSelection(
              new TextSelection($start, $end),
            );

            view.dispatch(transaction);
          },
        },
      }),
    ];
  },
});

export default Link;

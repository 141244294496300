/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useRef } from 'react';
import { StarterKit } from '@tiptap/starter-kit';
import {
  useEditor,
  type Content,
  type UseEditorOptions,
  type Editor,
} from '@tiptap/react';
import { Typography } from '@tiptap/extension-typography';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Underline } from '@tiptap/extension-underline';
import { TextStyle } from '@tiptap/extension-text-style';
import { cn } from '@symmetre-web/tailwind-config/utils/cn';
import {
  Link,
  HorizontalRule,
  Selection,
  Color,
  UnsetAllMarks,
  ResetMarksOnEnter,
  BackgroundColor,
  BulletList,
  OrderedList,
  Indent,
  TextAlign,
  DisableStylingShortcuts,
  ForceHeading,
  Variable,
  FontSize,
  MentionExt,
} from '@symmetre-web/text-editor/extensions';
import { getOutput } from '@symmetre-web/text-editor/utils/utils';
import { useThrottle } from '@symmetre-web/text-editor/hooks/useThrottle';

export interface UseTextEditorProps extends UseEditorOptions {
  value?: Content;
  output?: 'html' | 'json' | 'text';
  placeholder?: string;
  editorClassName?: string;
  throttleDelay?: number;
  onUpdate?: (content: Content) => void;
  onBlur?: (content: Content) => void;
  disableStylingKeystrokes?: boolean;
  forceHeading?: boolean;
  mentionableUsers?: { id: number | string; fullName: string }[];
}

const createExtensions = (
  placeholder: string,
  disableStylingKeystrokes: boolean,
  forceHeading: boolean,
  mentionableUsers: { id: number | string; fullName: string }[] = [],
) => [
  StarterKit.configure({
    horizontalRule: false,
    codeBlock: false,
    paragraph: { HTMLAttributes: { class: 'text-node' } },
    heading: { HTMLAttributes: { class: 'heading-node' } },
    blockquote: { HTMLAttributes: { class: 'block-node' } },
    bulletList: false,
    orderedList: false,
    code: { HTMLAttributes: { class: 'inline', spellcheck: 'false' } },
    dropcursor: { width: 2, class: 'ProseMirror-dropcursor border' },
  }),
  Link.configure({
    HTMLAttributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  }),
  Underline,
  Color,
  TextStyle,
  FontSize,
  Selection,
  Typography,
  UnsetAllMarks,
  HorizontalRule,
  ResetMarksOnEnter,
  BulletList,
  OrderedList,
  Placeholder.configure({ placeholder }),
  BackgroundColor,
  Indent,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  ...(disableStylingKeystrokes ? [DisableStylingShortcuts] : []),
  ...(forceHeading ? [ForceHeading] : []),
  Variable,
  ...(mentionableUsers.length > 0
    ? [
        MentionExt.configure({
          mentionable: mentionableUsers.map((user) => ({
            id: String(user.id),
            fullName: user.fullName,
          })),
        }),
      ]
    : []),
];

export const useTextEditor = ({
  value,
  output = 'html',
  placeholder = '',
  editorClassName,
  throttleDelay = 0,
  onUpdate,
  onBlur,
  disableStylingKeystrokes = false,
  forceHeading = false,
  mentionableUsers = [],
  ...props
}: UseTextEditorProps) => {
  const throttledSetValue = useThrottle(
    (value: Content) => onUpdate?.(value),
    throttleDelay,
  );
  const editorRef = useRef<Editor | null>(null);

  const handleUpdate = useCallback(
    (editor: Editor) => {
      throttledSetValue(getOutput(editor, output));
    },
    [output, throttledSetValue],
  );

  const handleCreate = useCallback(
    (editor: Editor) => {
      editorRef.current = editor;
      if (value && editor.isEmpty) {
        editor.commands.setContent(value);
      }
    },
    [value],
  );

  const handleBlur = useCallback(
    (editor: Editor) => onBlur?.(getOutput(editor, output)),
    [output, onBlur],
  );

  const editor = useEditor({
    extensions: createExtensions(
      placeholder,
      disableStylingKeystrokes,
      forceHeading,
      mentionableUsers,
    ),
    editorProps: {
      attributes: {
        autocomplete: 'off',
        autocorrect: 'off',
        autocapitalize: 'off',
        class: cn('focus:outline-none', editorClassName),
      },
    },
    onUpdate: ({ editor }) => handleUpdate(editor),
    onCreate: ({ editor }) => {
      handleCreate(editor);
    },
    onBlur: ({ editor }) => handleBlur(editor),
    ...props,
  });

  return editor;
};

export default useTextEditor;

import { extendTailwindMerge } from 'tailwind-merge';
import { TYPOGRAPHY_COMPONENTS } from '../config/typography';
import { THEME_SPACING } from '../config/spacing';

// todo replace with tailwind-merge
export const twMergeExtended = extendTailwindMerge({
  classGroups: {
    typography: Object.values(TYPOGRAPHY_COMPONENTS).map((key) => key.slice(1)),
  },
  theme: {
    spacing: Object.keys(THEME_SPACING),
  },
});

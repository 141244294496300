import * as React from 'react';
import type { Editor } from '@tiptap/react';
import type { FormatAction } from '@symmetre-web/text-editor/types';
import { ReactComponent as LinkIcon } from '@symmetre-web/text-editor/icons/linkIcon.svg';
import TiptapButton from '@symmetre-web/text-editor/components/ui/TextEditorButton';

type LinkStyleAction = 'link';

interface LinkStyle extends FormatAction {
  value: LinkStyleAction;
  label: React.ReactNode;
}

const FORMAT_ACTIONS: LinkStyle[] = [
  {
    value: 'link',
    label: <LinkIcon />,
    action: (editor) => {
      const previousUrl = editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      if (url === null) {
        return;
      }

      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }

      const href = url.startsWith('http') ? url : `https://${url}`;
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({
          href,
          target: '_blank',
          rel: 'noopener noreferrer',
        })
        .run();
    },
    isActive: (editor) => editor.isActive('link'),
    canExecute: (editor) => editor.can().setLink({ href: '' }),
    shortcuts: ['mod', 'K'],
  },
];

interface LinkStyleProps {
  editor: Editor;
}

export const LinkStyle: React.FC<LinkStyleProps> = ({ editor }) => {
  return (
    <div>
      {FORMAT_ACTIONS.map((action) => (
        <TiptapButton
          key={action.value}
          active={action.isActive(editor)}
          disabled={!action.canExecute(editor)}
          onClick={() => action.action(editor)}
          tooltipProps={{
            mainText: 'Link',
          }}
        >
          {action.label}
        </TiptapButton>
      ))}
    </div>
  );
};

export default LinkStyle;

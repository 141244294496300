import { Extension } from '@tiptap/react';

export const Indent = Extension.create({
  name: 'indent',

  addOptions() {
    return {
      types: ['paragraph', 'heading', 'blockquote', 'listItem'],
      indentRange: 24,
      minIndent: 0,
      maxIndent: 96,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            default: 0,
            parseHTML: (element) => {
              const indent = element.style.marginLeft;
              if (!indent) return 0;

              const value = parseInt(indent, 10);
              return isNaN(value) ? 0 : value;
            },
            renderHTML: (attributes) => {
              if (attributes.indent === 0) {
                return {};
              }

              return {
                style: `margin-left: ${attributes.indent}px`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    const changeIndent = (direction: number) => {
      return ({ tr, state, dispatch }) => {
        const { selection } = state;
        const { from, to } = selection;

        const { doc, schema } = state;
        const { types } = this.options;

        const allowedNodeTypes = Object.entries(schema.nodes)
          .filter(([, nodeType]) => types.includes(nodeType.name))
          .map(([, nodeType]) => nodeType);

        let hasChanges = false;

        doc.nodesBetween(from, to, (node, pos) => {
          if (!allowedNodeTypes.includes(node.type)) {
            return true;
          }

          const indent = node.attrs.indent || 0;
          let newIndent;

          if (direction > 0) {
            newIndent = Math.min(
              indent + this.options.indentRange,
              this.options.maxIndent,
            );
          } else {
            newIndent = Math.max(
              indent - this.options.indentRange,
              this.options.minIndent,
            );
          }

          if (indent === newIndent) {
            return true;
          }

          if (dispatch) {
            tr.setNodeMarkup(pos, undefined, {
              ...node.attrs,
              indent: newIndent,
            });
          }

          hasChanges = true;

          return true;
        });

        if (!hasChanges || !dispatch) {
          return false;
        }

        dispatch(tr);
        return true;
      };
    };

    return {
      increaseIndent: () => changeIndent(1),
      decreaseIndent: () => changeIndent(-1),
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => this.editor.commands.increaseIndent(),
      'Shift-Tab': () => this.editor.commands.decreaseIndent(),
    };
  },
});

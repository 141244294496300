import { Extension } from '@tiptap/react';
import {
  Plugin,
  PluginKey,
  type Transaction,
  type EditorState,
} from '@tiptap/pm/state';
import type { Node } from '@tiptap/pm/model';

export const ForceHeading = Extension.create({
  name: 'forceHeading',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('forceHeading'),
        appendTransaction: (
          transactions: Transaction[],
          _oldState: EditorState,
          newState: EditorState,
        ) => {
          if (!transactions.some((tr) => tr.docChanged)) {
            return null;
          }

          const { tr } = newState;
          let modified = false;

          const isVariableNode = (node: Node): boolean => {
            // Check if it's a variable node type
            if (node.type.name === 'variable') {
              return true;
            }

            // Fallback to attribute check for backward compatibility
            return Boolean(
              node.attrs &&
                node.attrs.class &&
                node.attrs.class.includes('variable'),
            );
          };

          const clearNodeAttributes = (node: Node, pos: number) => {
            if (isVariableNode(node)) {
              return;
            }

            if (node.marks.length > 0) {
              node.marks.forEach((mark) => {
                if (
                  mark.type.name === 'textStyle' ||
                  mark.type.name === 'color' ||
                  mark.type.name === 'fontSize' ||
                  mark.type.name === 'backgroundColor'
                ) {
                  tr.removeMark(pos, pos + node.nodeSize, mark.type);
                  modified = true;
                }
              });
            }
          };

          newState.doc.descendants((node: Node, pos: number) => {
            if (node.type.name === 'paragraph' && !isVariableNode(node)) {
              tr.setNodeMarkup(pos, this.editor.schema.nodes.heading, {
                level: 2,
              });
              modified = true;
            }

            clearNodeAttributes(node, pos);
          });

          return modified ? tr : null;
        },
      }),
    ];
  },
});

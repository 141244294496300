import * as React from 'react';
import type { Editor } from '@tiptap/react';
import {
  Popover,
  PopoverRef,
} from '@symmetre-web/text-editor/components/ui/Popover';
import { Icon } from '@symmetre-web/text-editor/components/ui/Icon';
import TiptapButton from '@symmetre-web/text-editor/components/ui/TextEditorButton';
import {
  getColorBorderClass,
  getColorActiveClass,
} from '@symmetre-web/text-editor/utils/colors';
import { ReactComponent as TextColorIcon } from '@symmetre-web/text-editor/icons/textColor.svg';
import { ReactComponent as BackgroundColorIcon } from '@symmetre-web/text-editor/icons/backgroundColor.svg';

interface ColorPickerProps {
  editor: Editor | null;
}

const COLORS = [
  ['#000000', '#424242', '#757575', '#BDBDBD', '#FFFFFF'],
  ['#EF5350', '#FF9800', '#FFEB3B', '#8BC34A', '#4CAF50'],
  ['#69F0AE', '#00BCD4', '#2196F3', '#3F51B5', '#9C27B0'],
] as const;

// TODO: replace with widgets/colorPicker/widget.tsx
const ColorPicker = ({
  onColorChange,
  currentColor,
  removeLabel,
  closePopover,
}: {
  onColorChange: (color: string | null) => void;
  removeLabel: string;
  closePopover: () => void;
  currentColor?: string;
}) => (
  <div>
    <button
      type="button"
      onClick={() => {
        onColorChange(null);
        closePopover();
      }}
      className="flex items-center w-full text-sm"
    >
      <span>{removeLabel}</span>
    </button>
    <hr className="my-1" />
    <div>
      {COLORS.map((row, rowIndex) => (
        <div key={rowIndex} className="flex gap-0.5 mb-0.5 justify-center">
          {row.map((color) => (
            <button
              key={color}
              type="button"
              onClick={() => {
                onColorChange(color);
                closePopover();
              }}
              className="p-0.5 rounded-sm hover:bg-gray-100"
            >
              <div
                style={{ backgroundColor: color }}
                className={`
                  w-6 h-6 rounded
                  ${getColorBorderClass(color)}
                  ${getColorActiveClass(currentColor, color)}
                `}
              />
            </button>
          ))}
        </div>
      ))}
    </div>
  </div>
);

const ColorStyle: React.FC<ColorPickerProps> = ({ editor }) => {
  if (!editor) return null;

  const textColorPopoverRef = React.useRef<PopoverRef>(null);
  const bgColorPopoverRef = React.useRef<PopoverRef>(null);

  const currentTextColor = editor.getAttributes('textStyle').color;
  const currentBgColor = editor.getAttributes('backgroundColor').color;

  const handleTextColorChange = (color: string | null) => {
    if (color === null) {
      editor.chain().focus().unsetColor().run();
    } else {
      editor.chain().focus().setColor(color).run();
    }
  };

  const handleBgColorChange = (color: string | null) => {
    if (color === null) {
      // @ts-ignore: The BackgroundColor extension adds these commands to the Editor
      editor.chain().focus().unsetBackgroundColor().run();
    } else {
      // @ts-ignore: The BackgroundColor extension adds these commands to the Editor
      editor.chain().focus().setBackgroundColor(color).run();
    }
  };

  return (
    <>
      <Popover
        ref={textColorPopoverRef}
        trigger="click"
        placement="bottom-start"
        template={
          <ColorPicker
            onColorChange={handleTextColorChange}
            currentColor={currentTextColor}
            removeLabel="Remove color"
            closePopover={() => textColorPopoverRef.current?.hide()}
          />
        }
        maxWidth="180px"
        hiddenArrow
        className=""
      >
        <TiptapButton
          active={currentTextColor}
          onClick={() => handleTextColorChange(currentTextColor)}
          tooltipProps={{
            mainText: 'Text color',
          }}
        >
          <div className="flex items-center">
            <TextColorIcon />
            <Icon iconName="bottom" />
          </div>
        </TiptapButton>
      </Popover>

      <Popover
        ref={bgColorPopoverRef}
        trigger="click"
        placement="bottom-start"
        template={
          <ColorPicker
            onColorChange={handleBgColorChange}
            currentColor={currentBgColor}
            removeLabel="Remove background"
            closePopover={() => bgColorPopoverRef.current?.hide()}
          />
        }
        maxWidth="180px"
        hiddenArrow
        className=""
      >
        <TiptapButton
          active={currentBgColor}
          onClick={() => handleBgColorChange(currentBgColor)}
          tooltipProps={{
            mainText: 'Background color',
          }}
        >
          <div className="flex items-center">
            <BackgroundColorIcon />
            <Icon iconName="bottom" />
          </div>
        </TiptapButton>
      </Popover>
    </>
  );
};

export default ColorStyle;

import React, { useEffect, useRef } from 'react';
import { PluginKey } from '@tiptap/pm/state';
import { ReactRenderer, Editor, Range } from '@tiptap/react';
import Popover, { PopoverRef } from 'apps/web/stories/Popover/Popover';
import { MENTION_MARKER } from '@symmetre-web/text-editor/utils/utils';

interface MentionListProps {
  items: { id: string; fullName: string }[];
  command: ({ id, label }: { id: string; label: string }) => void;
  editor: Editor;
  range: Range;
}

function MentionList({ items, command }: MentionListProps) {
  if (items.length === 0) {
    return null;
  }

  const selectItem = (index: number) => {
    const item = items[index];
    if (item) {
      command({ id: item.id, label: item.fullName });
    }
  };

  return (
    <div className="mention-list max-h-[300px] w-[180px] overflow-y-auto">
      {items.map((item, index) => (
        <div
          key={item.id}
          onClick={() => selectItem(index)}
          className="cursor-pointer border-b-light-10 px-4 py-2 text-neutral-850 hover:bg-bl hover:text-light-10"
        >
          {item.fullName}
        </div>
      ))}
    </div>
  );
}

function MentionPopover(props: {
  items: { id: string; fullName: string }[];
  command: ({ id, label }: { id: string; label: string }) => void;
  editor: Editor;
  clientRect: () => DOMRect | null;
  range: Range;
}) {
  const { items, command, editor, clientRect, range } = props;
  const popoverRef = useRef<PopoverRef>(null);

  useEffect(() => {
    if (popoverRef.current) {
      setTimeout(() => {
        popoverRef.current?.show();
      }, 0);
    }
  }, []);

  return (
    <Popover
      ref={popoverRef}
      template={
        <MentionList
          items={items}
          command={command}
          editor={editor}
          range={range}
        />
      }
      placement="bottom"
      trigger="manual"
      appendToBody
      hideOnExpandedAreaClick={false}
      getReferenceClientRect={clientRect}
      className="p-0"
      hiddenArrow
    />
  );
}

export interface MentionSuggestionOptions {
  mentionable: { id: string; fullName: string }[];
  char?: string;
  command?: ({
    editor,
    range,
    props,
  }: {
    editor: Editor;
    range: Range;
    props: { id: string; label: string };
  }) => void;
}

export const MentionPluginKey = new PluginKey('mention');

export const MentionSuggestion = (options: MentionSuggestionOptions) => {
  const char = options.char || MENTION_MARKER;

  return {
    char,
    items: ({ query }: { query: string }) => {
      return options.mentionable
        .filter((item) =>
          item.fullName.toLowerCase().startsWith(query.toLowerCase()),
        )
        .slice(0, 10);
    },

    render: () => {
      let component: ReactRenderer | null = null;

      const onStart = (props: {
        editor: Editor;
        clientRect: () => DOMRect | null;
        items: { id: string; fullName: string }[];
        command: ({ id, label }: { id: string; label: string }) => void;
        range: Range;
      }) => {
        component = new ReactRenderer(MentionPopover, {
          props,
          editor: props.editor,
        });
      };

      const onUpdate = (props: {
        editor: Editor;
        clientRect: () => DOMRect | null;
        items: { id: string; fullName: string }[];
        command: ({ id, label }: { id: string; label: string }) => void;
        range: Range;
      }) => {
        component?.updateProps(props);
      };

      const onKeyDown = (props: { event: KeyboardEvent }) => {
        if (props.event.key === 'Escape') {
          component?.destroy();
          return true;
        }
        return false;
      };

      const onExit = () => {
        component?.destroy();
      };

      return {
        onStart,
        onUpdate,
        onKeyDown,
        onExit,
      };
    },

    command: ({
      editor,
      range,
      props,
    }: {
      editor: Editor;
      range: Range;
      props: { id: string; label: string };
    }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .insertContent([
          {
            type: 'mention',
            attrs: props,
          },
          {
            type: 'text',
            text: ' ',
          },
        ])
        .run();
    },
  };
};

export default MentionSuggestion;

/* eslint-disable max-params */
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { cn } from '@symmetre-web/tailwind-config/utils/cn';
import { HeadingStyle } from '@symmetre-web/text-editor/components/section/HeadingStyle';
import { TextStyle } from '@symmetre-web/text-editor/components/section/TextStyle';
import { EditorContent, type Content, type Editor } from '@tiptap/react';
import ColorStyle from '@symmetre-web/text-editor/components/section/ColorStyle';
import FontSizeStyle from '@symmetre-web/text-editor/components/section/FontSizeStyle';
import ListStyle from '@symmetre-web/text-editor/components/section/ListStyle';
import LinkStyle from '@symmetre-web/text-editor/components/section/LinkStyle';
import IndentStyle from '@symmetre-web/text-editor/components/section/IndentStyle';
import TextAlignStyle from '@symmetre-web/text-editor/components/section/TextAlignStyle';
import HorizontalRuleButton from '@symmetre-web/text-editor/components/section/HorizontalRuleButton';
import BlockquoteButton from '@symmetre-web/text-editor/components/section/BlockquoteButton';
import UndoRedo from '@symmetre-web/text-editor/components/section/UndoRedo';
import VariableButton from '@symmetre-web/text-editor/components/section/VariableButton';
import {
  useTextEditor,
  type UseTextEditorProps,
} from '@symmetre-web/text-editor/hooks/useTextEditor';

import '@symmetre-web/text-editor/styles/index.css';
import { ProseMirrorEditor } from '@symmetre-web/text-editor/types';

export type ToolbarItem =
  | 'text'
  | 'color'
  | 'fontSize'
  | 'heading'
  | 'link'
  | 'list'
  | 'indent'
  | 'align'
  | 'rule'
  | 'blockquote'
  | 'undoRedo'
  | 'variable';

export type ToolbarGroupConfig = {
  name: string;
  items: ToolbarItem[];
};

export interface TextEditorProps extends Omit<UseTextEditorProps, 'onUpdate'> {
  value?: Content;
  onChange?: (value: Content) => void;
  className?: string;
  classes?: {
    content?: string;
    wrapper?: string;
  };
  toolbarGroups?: ToolbarGroupConfig[];
  variables?: any[];
  onReady?: (editor: Editor) => void;
  onChangeStart?: (editor: Editor) => void;
  disableStylingKeystrokes?: boolean;
  forceHeading?: boolean;
  customEditor?: Editor;
  mentionableUsers?: { id: number | string; fullName: string }[];
}

const ToolbarGroup = ({ children }: { children: React.ReactNode }) => (
  <div className="tiptap-toolbar-group">{children}</div>
);

const renderToolbarItem = (
  item: ToolbarItem,
  editor: Editor,
  variables: any[],
) => {
  switch (item) {
    case 'text':
      return <TextStyle editor={editor} />;
    case 'color':
      return <ColorStyle editor={editor} />;
    case 'fontSize':
      return <FontSizeStyle editor={editor} />;
    case 'heading':
      return <HeadingStyle editor={editor} />;
    case 'link':
      return <LinkStyle editor={editor} />;
    case 'list':
      return <ListStyle editor={editor} />;
    case 'indent':
      return <IndentStyle editor={editor} />;
    case 'align':
      return <TextAlignStyle editor={editor} />;
    case 'rule':
      return <HorizontalRuleButton editor={editor} />;
    case 'blockquote':
      return <BlockquoteButton editor={editor} />;
    case 'undoRedo':
      return <UndoRedo editor={editor} />;
    case 'variable':
      return <VariableButton editor={editor} variables={variables ?? []} />;
    default:
      return null;
  }
};

const DEFAULT_TOOLBAR_GROUPS: ToolbarGroupConfig[] = [
  {
    name: 'textFormatting',
    items: ['text', 'color', 'fontSize'],
  },
  {
    name: 'headings',
    items: ['heading'],
  },
  {
    name: 'links',
    items: ['link'],
  },
  {
    name: 'lists',
    items: ['list'],
  },
  {
    name: 'indentation',
    items: ['indent'],
  },
  {
    name: 'alignment',
    items: ['align'],
  },
  {
    name: 'blocks',
    items: ['rule', 'blockquote'],
  },
  {
    name: 'history',
    items: ['undoRedo'],
  },
];

const MainToolbar = ({
  editor,
  variables = [],
  toolbarGroups = DEFAULT_TOOLBAR_GROUPS,
}: {
  editor: Editor;
  variables?: any[];
  toolbarGroups?: ToolbarGroupConfig[];
}) => (
  <div className="tiptap-toolbar">
    <div className="tiptap-toolbar-items">
      {toolbarGroups.map((group, index) => (
        <ToolbarGroup key={`${group.name}-${index}`}>
          {group.items.map((item, itemIndex) => (
            <React.Fragment key={`${item}-${itemIndex}`}>
              {renderToolbarItem(item, editor, variables || [])}
            </React.Fragment>
          ))}
        </ToolbarGroup>
      ))}
    </div>
  </div>
);

// Define the ref interface for external use
export interface TextEditorRef {
  getProseMirrorEditor: () => ProseMirrorEditor | null;
}

export const TextEditor = forwardRef<TextEditorRef, TextEditorProps>(
  (
    {
      value,
      onChange,
      classes,
      toolbarGroups,
      variables,
      disableStylingKeystrokes = false,
      forceHeading = false,
      placeholder = '',
      mentionableUsers,
      ...props
    }: TextEditorProps,
    ref: React.ForwardedRef<TextEditorRef>,
  ) => {
    const editorContainerRef = useRef<HTMLDivElement>(null);
    const editor = useTextEditor({
      value,
      onUpdate: onChange,
      disableStylingKeystrokes,
      forceHeading,
      placeholder,
      mentionableUsers,
      ...props,
    });

    if (!editor) return null;

    useImperativeHandle(ref, () => ({
      getProseMirrorEditor: () => {
        if (!editorContainerRef.current) return null;
        const proseMirrorElement =
          editorContainerRef.current.querySelector('.ProseMirror');
        if (!proseMirrorElement) return null;
        return (proseMirrorElement as any).editor;
      },
    }));

    return (
      <div className={cn('tiptap-editor', classes?.wrapper)}>
        {toolbarGroups && (
          <MainToolbar
            editor={editor}
            toolbarGroups={toolbarGroups}
            variables={variables}
          />
        )}
        <EditorContent
          editor={editor}
          className={cn('minimal-tiptap-editor', classes?.content)}
          ref={editorContainerRef}
        />
      </div>
    );
  },
);

TextEditor.displayName = 'TextEditor';

export default TextEditor;

export function getColorBorderClass(color: string): string {
  return color === '#FFFFFF'
    ? 'border border-solid border-[#E0E0E0]'
    : 'border-none';
}

export function getColorActiveClass(
  currentColor: string | undefined,
  color: string,
): string {
  return currentColor === color
    ? 'outline outline-2 outline-[#2196F3] outline-offset-1'
    : '';
}

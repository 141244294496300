import { TextStyle } from '@tiptap/extension-text-style';
import {
  extractCssAttributeFromString,
  applyStyleProperty,
} from '@symmetre-web/text-editor/utils/utils';

export const FontSize = TextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      fontSize: {
        default: null,
        parseHTML: (element: HTMLElement) => {
          const style = element.getAttribute('style');
          return extractCssAttributeFromString(style, 'font-size');
        },
        renderHTML: (attributes: { fontSize: string | null }) => {
          return applyStyleProperty('font-size', attributes.fontSize);
        },
      },
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setFontSize:
        (size: string | null) =>
        ({ chain }: { chain: () => any }) => {
          return chain().setMark(this.name, { fontSize: size }).run();
        },
      unsetFontSize:
        () =>
        ({ chain }: { chain: () => any }) => {
          return chain().setMark(this.name, { fontSize: null }).run();
        },
    };
  },
});

import React from 'react';
import { cn } from '@symmetre-web/tailwind-config/utils/cn';

// TODO: FE-3618 Move tooltip component to UI package
import { Tooltip } from 'apps/web/stories/Tooltip/Tooltip';

export interface TiptapButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  tooltipProps?: React.ComponentProps<typeof Tooltip>;
  active?: boolean;
}

export const TiptapButton = ({
  active = false,
  className,
  children,
  tooltipProps = {
    disabled: true,
  },
  ...props
}: TiptapButtonProps) => {
  const rawButton = (
    <button
      className={cn(
        'tiptap-button',
        {
          'is-active': active,
        },
        className,
      )}
      type="button"
      {...props}
    >
      {children}
    </button>
  );

  if (tooltipProps.disabled) return rawButton;

  return <Tooltip {...tooltipProps}>{rawButton}</Tooltip>;
};

export default TiptapButton;

import { cn } from '@/shared/lib/css/cn';
import { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react/headless';
import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { animated, useSpring } from 'react-spring';
import TooltipArrow from '@/stories/Popover/TooltipArrow';
import '@/stories/Popover/popover.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { usePopoverTriggered } from 'bundles/Shared/components/LeftSidebar/hooks/usePopoverTriggered';
import { Instance } from 'tippy.js';
import { ArrowPositions } from 'types/Tippy';
import { ModifyArrowPosition } from '@/stories/utils/tippyUtils';

type ExcludedTippyProps = Omit<
  TippyProps,
  | 'render'
  | 'interactive'
  | 'onCreate'
  | 'animation'
  | 'onMount'
  | 'onHide'
  | 'ref'
  | 'trigger'
  | 'children'
>;

export interface PopoverProps extends ExcludedTippyProps {
  template: React.ReactNode;
  className?: string;
  classes?: {
    spanContainer?: string;
  };
  trigger?: StringAndOptions<'click' | 'mouseenter'>;
  appendToBody?: boolean;
  hiddenArrow?: boolean;
  theme?: 'dark' | 'light';
  maxWidth?: CSSProperties['maxWidth'];
  hideOnExpandedAreaClick?: boolean;
  style?: CSSProperties;
  arrowPosition?: FlattenEnum<ArrowPositions>;
  spanContainerTabIndex?: React.HTMLAttributes<HTMLSpanElement>['tabIndex'];
  children?:
    | React.ReactNode
    | (({ triggered }: { triggered: boolean }) => React.ReactNode);
}

export interface PopoverRef {
  hide: () => void;
  show: () => void;
}

export const DEFAULT_DROPDOWN_OFFSET: [number, number] = [0, 2];

export const Popover = forwardRef<PopoverRef, PopoverProps>(
  (
    {
      template,
      hiddenArrow,
      theme = 'light',
      children,
      appendToBody,
      maxWidth = '11.5rem',
      arrowPosition = 'center',
      hideOnExpandedAreaClick,
      style,
      className,
      placement,
      spanContainerTabIndex = 0,
      trigger,
      classes,
      ...props
    },
    ref,
  ) => {
    const instance = useRef<Instance>(null);
    const { triggered, ...triggeredProps } = usePopoverTriggered({
      disabled: typeof children !== 'function',
    });
    const config = { duration: 150 };
    const initialStyles = { opacity: 0 };
    const [styles, setSpring] = useSpring(() => initialStyles);
    const onMount = () => {
      setSpring({
        opacity: 1,
        config,
      });
    };
    const onHide: TippyProps['onHide'] = ({ unmount }) => {
      setSpring({
        ...initialStyles,
        onRest: unmount,
        config: { ...config, clamp: true },
      });
    };

    useImperativeHandle(ref, () => ({
      show: () => instance.current?.show(),
      hide: () => {
        instance.current?.hide();
        triggeredProps.onHidden(instance.current!);
      },
    }));

    return (
      <Tippy
        interactive
        onCreate={(tippy) => {
          instance.current = tippy;
        }}
        render={(attrs) => (
          <animated.div style={styles}>
            <div
              className={cn(
                'sre-popover sre-popover_custom',
                { [`sre-popover_${theme}`]: theme },
                className,
              )}
              style={{ ...style, maxWidth }}
              tabIndex={-1}
              {...attrs}
              onClick={
                hideOnExpandedAreaClick
                  ? () => instance.current?.hide()
                  : undefined
              }
            >
              {!hiddenArrow && <TooltipArrow />}
              {template}
            </div>
          </animated.div>
        )}
        offset={[0, 2]}
        animation
        onMount={onMount}
        onHide={onHide}
        placement={placement}
        popperOptions={{
          // eslint-disable-next-line new-cap
          modifiers: [ModifyArrowPosition({ arrowPosition, placement })],
        }}
        appendTo={appendToBody ? () => document.body : undefined}
        {...triggeredProps}
        trigger={trigger as string}
        {...props}
      >
        {props.reference == null ? (
          <span
            className={cn('inline-flex', classes?.spanContainer)}
            tabIndex={spanContainerTabIndex}
          >
            {typeof children === 'function'
              ? children({ triggered })
              : children}
          </span>
        ) : undefined}
      </Tippy>
    );
  },
);

export const DropdownPopover = forwardRef<PopoverRef, PopoverProps>(
  ({ className, ...props }, ref) => {
    return (
      <Popover
        ref={ref}
        hideOnExpandedAreaClick
        offset={DEFAULT_DROPDOWN_OFFSET}
        placement="bottom-start"
        trigger="click"
        className={cn('p-0', className)}
        hiddenArrow
        {...props}
      />
    );
  },
);

export default Popover;

import { cn } from '@/shared/lib/css/cn';
import Tooltip from '@/stories/Tooltip/Tooltip';
import React, { type ComponentProps } from 'react';
import { IconsId } from 'types/sre-icons';
import '@/stories/Icon/icon.css';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  iconName: IconsId;
  className?: string;
  backgroundColor?: string;
  padding?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';
  tooltipProps?: ComponentProps<typeof Tooltip>;
}

export const Icon = ({
  className,
  iconName,
  onClick,
  backgroundColor,
  padding,
  color,
  style,
  tooltipProps = {
    disabled: true,
  },
  ...props
}: Props) => {
  const isClickable = typeof onClick === 'function';
  const rawIcon = (
    <i
      aria-hidden="true"
      className={cn('sre-icon', `sre-icon-${iconName}`, className, {
        'sre-icon_clickable': isClickable,
        [`p-${padding}`]: padding,
      })}
      onClick={onClick}
      style={{ backgroundColor, color, borderRadius: '0.5rem', ...style }}
      {...props}
    />
  );

  if (tooltipProps.disabled) return rawIcon;

  return <Tooltip {...tooltipProps}>{rawIcon}</Tooltip>;
};

export default Icon;

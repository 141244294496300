import { Node, mergeAttributes } from '@tiptap/react';
import { Suggestion } from '@tiptap/suggestion';
import {
  MentionSuggestion,
  MentionPluginKey,
} from '@symmetre-web/text-editor/extensions/mention/suggestion';
import {
  MENTION_MARKER,
  wrapMentionLabel,
} from '@symmetre-web/text-editor/utils/utils';

export interface MentionOptions {
  mentionable: { id: string; fullName: string }[];
  suggestion?: Partial<typeof MentionSuggestion>;
  HTMLAttributes?: Record<string, any>;
}

export const MentionExt = Node.create<MentionOptions>({
  name: 'mention',
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      mentionable: [],
      suggestion: {
        char: MENTION_MARKER,
        pluginKey: MentionPluginKey,
        command: ({ editor, range, props }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .insertContent([
              {
                type: this.name,
                attrs: props,
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run();
        },
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            'data-id': attributes.id,
          };
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-label'),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            'data-label': attributes.label,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-mention]',
        getAttrs: (element) => {
          if (typeof element === 'string') {
            return false;
          }

          const id = element.getAttribute('data-id');
          const label = element.getAttribute('data-label');

          if (!id || !label) {
            return false;
          }

          return { id, label };
        },
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-mention': '' },
        this.options.HTMLAttributes,
        HTMLAttributes,
        { class: 'mention' },
      ),
      wrapMentionLabel(node.attrs.label),
    ];
  },

  renderText({ node }) {
    return wrapMentionLabel(node.attrs.label);
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...MentionSuggestion({
          mentionable: this.options.mentionable,
          ...this.options.suggestion,
        }),
      }),
    ];
  },
});

export default MentionExt;

import React from 'react';
import { Editor } from '@tiptap/react';
import TiptapButton from '@symmetre-web/text-editor/components/ui/TextEditorButton';
import { ReactComponent as HorizontalRuleIcon } from '@symmetre-web/text-editor/icons/horizontalRule.svg';

interface HorizontalRuleButtonProps {
  editor: Editor;
}

const HorizontalRuleButton: React.FC<HorizontalRuleButtonProps> = ({
  editor,
}) => {
  const canInsertHorizontalRule = editor
    .can()
    .chain()
    .focus()
    .setHorizontalRule()
    .run();

  return (
    <TiptapButton
      onClick={() => editor.chain().focus().setHorizontalRule().run()}
      disabled={!canInsertHorizontalRule}
      tooltipProps={{
        mainText: 'Horizontal Line',
      }}
    >
      <HorizontalRuleIcon />
    </TiptapButton>
  );
};

export default HorizontalRuleButton;

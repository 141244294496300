import type { Editor } from '@tiptap/react';
import TiptapButton from '@symmetre-web/text-editor/components/ui/TextEditorButton';
import { ReactComponent as DecreaseIndentIcon } from '@symmetre-web/text-editor/icons/decreaseIndent.svg';
import { ReactComponent as IncreaseIndentIcon } from '@symmetre-web/text-editor/icons/increaseIndent.svg';

interface IndentStyleProps {
  editor: Editor;
}

const IndentStyle = ({ editor }: IndentStyleProps) => {
  return (
    <div className="flex gap-1">
      <TiptapButton
        onClick={() => editor.chain().focus().decreaseIndent().run()}
        title="Decrease indent"
        tooltipProps={{
          mainText: 'Decrease indent',
        }}
      >
        <DecreaseIndentIcon />
      </TiptapButton>
      <TiptapButton
        onClick={() => editor.chain().focus().increaseIndent().run()}
        title="Increase indent"
        tooltipProps={{
          mainText: 'Increase indent',
        }}
      >
        <IncreaseIndentIcon />
      </TiptapButton>
    </div>
  );
};

export default IndentStyle;

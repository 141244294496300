import type { Editor } from '@tiptap/react';
import { Button } from '@symmetre-web/text-editor/components/ui/Button';
import {
  Dropdown,
  DropdownItem,
} from '@symmetre-web/text-editor/components/ui/Dropdown';
import { Tooltip } from '@symmetre-web/text-editor/components/ui/Tooltip';

export const VariableButton = ({
  editor,
  variables,
}: {
  editor: Editor;
  variables: any[];
}) => {
  const insertVariable = (variable: any) => {
    editor
      .chain()
      .focus()
      .insertContent({
        type: 'variable',
        attrs: {
          id: variable.name,
          label: variable.label,
        },
      })
      .run();
  };

  return (
    <Dropdown
      items={
        <>
          {variables.map((variable) => (
            <DropdownItem
              key={variable.name}
              onClick={() => insertVariable(variable)}
            >
              {variable.label}
            </DropdownItem>
          ))}
        </>
      }
      placement="bottom-start"
    >
      <Tooltip mainText="Variables">
        <div className="w-[120px]">
          <Button
            variant="secondary"
            size="xs"
            iconPosition="right"
            iconName="bottom"
            fluid
            className="flex justify-between"
          >
            <div className="flex justify-between">
              <div className="text-dark-20">Variables</div>
            </div>
          </Button>
        </div>
      </Tooltip>
    </Dropdown>
  );
};

export default VariableButton;

import { Node } from '@tiptap/react';

const Variable = Node.create({
  name: 'variable',
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      label: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.variable',
        getAttrs: (node) => {
          if (typeof node === 'string') return {};

          const element = node as HTMLElement;
          return {
            id: element.getAttribute('data-id') || element.textContent,
            label: element.textContent || element.getAttribute('data-label'),
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      {
        class: 'variable',
        contenteditable: 'false',
        ...HTMLAttributes,
      },
      HTMLAttributes.label,
    ];
  },
});

export { Variable };

/**
 * generates spacing from 1px to 99px
 * 100 stands for < w-100: 100%; > need to refactor it
 * very confusing
 */
const extendedSpacing = Object.fromEntries(
  Array.from({ length: 99 }, (_, idx) => [idx + 1, `${idx + 1}px`]),
);

export const THEME_SPACING = {
  0: '0',
  DEFAULT: '1px',
  xxs: '0.125rem',
  xs: '0.25rem',
  s: '0.5rem',
  m: '1rem',
  l: '1.5rem',
  xl: '2rem',
  xxl: '2.5rem',
  ...extendedSpacing,

  // New spacing css variables! Further on use only them
  px: '1px',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  4.5: '1.125rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  9.5: '2.375rem',
  10: '2.5rem',
  11: '2.75rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  18: '4.5rem',
  20: '5rem',
};

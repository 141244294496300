import { cn } from '@/shared/lib/css/cn';
import React, { ComponentProps } from 'react';
import { OverlaySpinner } from '@/stories/OverlaySpinner/OverlaySpinner';
import { Tooltip } from '@/stories/Tooltip/Tooltip';
import { IconsId } from '@/types/sre-icons';
import { Icon } from '@/stories/Icon/Icon';
import '@/stories/Button/button.css';

export interface IButton
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  tooltipProps?: ComponentProps<typeof Tooltip>;
  size?: 'xs' | 's' | 'm' | 'l';
  iconPosition?: 'left' | 'right';
  iconName?: IconsId;
  altIconName?: IconsId;
  altIconPosition?: 'left' | 'right';
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'ghost'
    | 'group-secondary';
  fluid?: boolean;
  hasDot?: boolean;
  isDarkText?: boolean;
  offHoverStyles?: boolean;
  loading?: boolean;
  iconClasses?: string;
  type?: 'submit' | 'reset' | 'button';
  blurAfterClick?: boolean;
  selected?: boolean;
}

export const Button = ({
  tooltipProps = {
    disabled: true,
  },
  size = 'm',
  iconPosition = 'left',
  type = 'button',
  className,
  variant = 'secondary',
  children,
  disabled,
  loading,
  iconName,
  altIconName,
  altIconPosition,
  iconClasses,
  fluid,
  hasDot,
  onClick,
  offHoverStyles,
  isDarkText,
  blurAfterClick,
  selected,
  ...props
}: IButton) => {
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (blurAfterClick) {
      e.currentTarget.blur();
    }
    onClick?.(e);
  };
  const icon = (
    <Icon
      className={cn(variant === 'secondary' && 'text-neutral-450', iconClasses)}
      iconName={iconName!}
    />
  );
  const altIcon = (
    <Icon
      className={cn(variant === 'secondary' && 'text-neutral-450', iconClasses)}
      iconName={altIconName!}
    />
  );
  const rawButton = (
    <button
      className={cn(
        cn(
          'sre-button',
          'text-nowrap',
          `sre-button_size-${size}`,
          variant && `sre-button_${variant}`,
          {
            'border text-info-055 shadow-z-020': variant === 'secondary',
            'rounded-none border-none text-info-055  shadow-none first:rounded-l-[8px] last:rounded-r-[8px] hover:bg-neutral-100':
              variant === 'group-secondary',
            relative: hasDot,
          },
          {
            _fullwidth: fluid,
            '_off-hover-styles': offHoverStyles,
            'border-[2px] border-info-050': selected,
          },
          {
            'text-neutral-850 hover:!text-neutral-650': isDarkText,
          },
          className,
        ),
      )}
      type={type}
      disabled={disabled}
      onClick={handleOnClick}
      {...props}
    >
      {hasDot && (
        <div className="absolute -right-0.5 -top-0.5 flex size-2 rounded-full bg-info-055" />
      )}
      {iconName && iconPosition === 'left' && !loading && icon}
      {iconName && altIconPosition === 'left' && !loading && altIcon}
      {loading && (
        <OverlaySpinner size="small" overlayClasses="bg-transparent" inline />
      )}
      {children}
      {iconName && iconPosition === 'right' && !loading && icon}
      {iconName && altIconPosition === 'right' && !loading && altIcon}
    </button>
  );

  if (tooltipProps.disabled) return rawButton;

  return <Tooltip {...tooltipProps}>{rawButton}</Tooltip>;
};

export default Button;
